import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MainBtn from "../components/button/mainBtn"
import SuccessModal from "../components/newsletter/successModal"
import emailIcon from "../assets/images/contact/icon-email.svg"
import formIcon from "../assets/images/contact/form-icon.png"
import locationIcon from "../assets/images/contact/icon-location.svg"
import ContactImage from "../assets/images/contact/contact-side-image.svg"
import modalImg from "../assets/images/contact/illustration-success.svg"

const ContactUs = () => {
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (success) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  })

  const useForm = initialValues => {
    const [inputs, setInputs] = useState(initialValues)
    const [errors, setErrors] = useState([])

    const handleSubmit = async event => {
      event.preventDefault()
      const validationErrors = validate(inputs)
      const noErrors = Object.keys(validationErrors).length === 0
      setErrors(validationErrors)
      if (noErrors) {
        setErrors(noErrors)

        try {
          const response = await fetch("https://api.servbees.com/contact-us", {
            method: "POST",
            headers: {
              Accepts: "application/json;charset=utf-8",
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({
              full_name: inputs.name,
              email: inputs.email,
              message: inputs.message,
            }),
          })

          if (response.ok) {
            setInputs({
              name: "",
              email: "",
              message: "",
            })
            setSuccess(true)
          } else {
            throw new Error("Error sending email")
          }
        } catch (error) {
          console.error(error.message || "There was an error sending email")
        }
      } else {
        console.log(errors, validationErrors)
      }
    }
    const handleInputChange = event => {
      event.persist()
      setInputs(inputs => ({
        ...inputs,
        [event.target.name]: event.target.value,
      }))
    }
    return {
      handleSubmit,
      handleInputChange,
      inputs,
    }
  }

  const validate = inputs => {
    //Email errors
    const errors = {}
    if (!inputs.email) {
      errors.email = "Check Email"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)) {
      errors.email = "Invalid email address"
    }

    //Name Errors
    if (!inputs.name == null) {
      errors.name = "Cannot be empty"
    }
    return errors
  }

  const { inputs, handleInputChange, handleSubmit, errors } = useForm(
    {
      name: "",
      email: "",
      message: "",
    },
    validate
  )

  const closeSuccess = () => {
    if (success) {
      setSuccess(false)
    }
  }

  return (
    <>
      <div
        className={`overlay-mask ${success ? "is-active" : ""}`}
        onClick={closeSuccess}
        role="presentation"
      ></div>
      <Layout className="contact">
        <SEO
          pageTitle="Contact Us"
          title="Contact Us | Servbees Support Center"
          description="Buzz us anytime. Servbees is an all-in-one marketplace and gig platform, ready to serve the community!"
        />
        <div className="container">
          <div className="contact-wrapper">
            <div className="content-holder">
              <img src={ContactImage} className="contact-image" alt="" />
              <ul>
                <li>
                  <a href="mailto:hello@servbees.com" className="menu-link">
                    <img
                      src={emailIcon}
                      alt="contact us icon"
                      className="menu-icon"
                    />
                    <p className="link-text">hello@servbees.com</p>
                  </a>
                </li>
                {/* <li className="contact-desktop">
                  <a href="tel:+656988-3863" className="menu-link">
                    <img
                      src={contactIcon}
                      alt="contact us icon"
                      className="menu-icon"
                    />
                    <p className="link-text">+65 6988 3863</p>
                  </a>
                </li> */}
                {/* <li className="contact-mobile">
                  <a href="tel:+656988-3863" className="menu-link">
                    <img
                      src={contactIcon}
                      alt="contact us icon"
                      className="menu-icon"
                    />
                    <p className="link-text">+65 6988 3863</p>
                  </a>
                </li> */}
                <li>
                  <div className="menu-link">
                    <img
                      src={locationIcon}
                      alt="contact us icon"
                      className="menu-icon"
                      style={{ placeSelf: "baseline" }}
                    />
                    <p className="link-text">
                      PH: 360Verve, OPC
                      <br />
                      41F, Pioneer Highlands West Tower 2, Madison St.,
                      Mandaluyong City Ph
                    </p>
                  </div>
                </li>
                <li>
                  <div className="menu-link">
                    <img
                      src={locationIcon}
                      alt="contact us icon"
                      className="menu-icon"
                      style={{ placeSelf: "baseline" }}
                    />
                    <p className="link-text">
                      SG: 360Pixels Pte. Ltd
                      <br />9 Temasek Boulevard, #07-01 Suntec Tower 2.
                      Singapore 038989
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="content-holder">
              <div className="contact-form-holder">
                <h1 className="contact-title">Contact Us</h1>
                <p className="contact-sub-title">
                  Interested in being a partner? Thinking of offering your
                  products or services on Servbees? Have some questions? Get in
                  touch with us so we can get buzzy together!
                </p>
                <form className="contact-us-form" onSubmit={handleSubmit}>
                  <div className="input-holder">
                    <input
                      type="text"
                      name="name"
                      className="contact-input"
                      onChange={handleInputChange}
                      value={inputs.name}
                      required
                    />
                    <span className="placeholder">Full Name</span>
                  </div>
                  <div className="input-holder">
                    <input
                      type="email"
                      name="email"
                      onChange={handleInputChange}
                      value={inputs.email}
                      className="contact-input"
                      required
                    />
                    <span className="placeholder">Email Address</span>
                  </div>
                  <textarea
                    className="message"
                    name="message"
                    onChange={handleInputChange}
                    value={inputs.message}
                    placeholder="Your Message"
                    required
                    maxLength="300"
                  />
                  <div className="submit-holder">
                    <div className="agree-holder">
                      <img
                        src={formIcon}
                        alt="form icon"
                        className="form-icon"
                      />
                      <p className="agree-text">
                        By submitting, you agree to our
                        <Link to="/privacy-policy" className="privacy-link">
                          {" "}
                          Privacy Policy
                        </Link>
                      </p>
                    </div>
                    <div className="submit-btn">
                      {success ? (
                        <MainBtn text="Sent" type="submit" />
                      ) : (
                        <MainBtn text="Submit" type="submit" />
                      )}
                      {errors && <p>errors</p>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {success ? (
        <>
          <SuccessModal
            handleSubmit={closeSuccess}
            success={success}
            closeSuccess={closeSuccess}
            modalImg={modalImg}
            mainText={"Thanks for messaging us!"}
            subText={
              "Your message has been successfully sent. We’ll review your inquiry and get back to you very soon. "
            }
          />
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default ContactUs
